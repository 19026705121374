import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import "./home.scss";
import SEO from "../../components/SEO/SEO";

const Home = () => {
  return (
    <>
      <SEO
        name={"Home"}
        title={"Denzell Merisier"}
        description={"Home Page"}
        type={"website"}
        keywords={
          "home, denzell merisier, software engineer, fullstack, web developer, web applications, websites"
        }
      />
      <div className="page__container">
        <header>
          <Navbar />
        </header>
        <div className="content__wrap">
          <main>
            <section className="home__section">
              <div className="home__grid">
                <div>
                  <h1 className="home__title">Hello, I'm Denzell Merisier</h1>
                </div>
                <div>
                  <h3 className="home__subtitle">
                    A Fullstack Software Engineer, creating Websites and Web
                    Applications.
                  </h3>
                </div>
                <div>
                  <Link to="/projects" className="home__btn">
                    PROJECTS
                  </Link>
                </div>
              </div>
            </section>
          </main>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Home;
