import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./contact.scss";
import { useForm } from "@formspree/react";
import SEO from "../../components/SEO/SEO";

const Contact = () => {
  const link = process.env.REACT_APP_LINK;
  console.log(link);
  const [state, handleSubmit] = useForm("myyrwnlq");

  if (state.succeeded) {
    return <p>Thanks for reaching out! I will get back to you soon!</p>;
  }

  return (
    <>
      <SEO
        title={"Contact"}
        description={"Contact me for any inquiries"}
        name={"Contact"}
        type={"website"}
        keywords={
          "contact, email, message, form, contact form, contact me, email me, message me, contact information"
        }
      />
      <div className="page__container">
        <header>
          <Navbar />
        </header>
        <main>
          <section className="contact__section">
            <h1 className="contact__section-title">Contact</h1>
            <form onSubmit={handleSubmit} className="form__container">
              <div className="form__container-name">
                <label htmlFor="name">Name</label>
                <br />
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                />
              </div>
              <div className="form__container-email">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email"
                />
              </div>
              <div className="form__container-message">
                <label htmlFor="message">Message</label>
                <br />
                <textarea
                  id="message"
                  placeholder="Enter Your Message"
                  name="message"
                />
              </div>
              <div className="form__container-btn">
                <button type="submit" disabled={state.submiting}>
                  Submit
                </button>
              </div>
            </form>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
