import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./about.scss";
import SEO from "../../components/SEO/SEO";

const About = () => {
  return (
    <>
      <SEO
        name={"About"}
        title={"About"}
        description={"About Page"}
        type={"website"}
        keywords={
          "about, about me, denzell merisier, software engineer, fullstack, web developer, web applications, websites"
        }
      />
      <div className="page__container">
        <header>
          <Navbar />
        </header>
        <main>
          <section className="about__section">
            <h1 className="about__section--title">About</h1>
            <div className="about__container">
              <p className="about__container--text">
                I recently graduated from BrainStation where I studied Software
                Engineering. I have a background in computers in general.
                Throughout high school, I took many courses related to this
                field, such as computer programming, communication technology
                and business. I became interested in computers because it always
                fascinated me with how things function. I went from being
                interested in computer hardware, how the components work
                together to produce one result, this interest went further when
                I started to question how the web works. Working on my capstone
                project allowed me to showcase my skills and knowledge as a
                Full-Stack Software Engineer. Other projects from BrainStation
                also allowed me to sharpen my skills in ReactJS, NodeJS, JWT and
                ExpressJS. Thanks to my experience from high school, picking
                JavaScript was straightforward as I had the basic knowledge of
                object-oriented programming and basic knowledge of Java. I am
                super excited to work in the field of Software because it
                permits me to demonstrate my skills and knowledge. It is an
                opportunity to learn new skills along the way and meet new
                colleagues. I am excited about this journey in Software
                Engineering. It will be work but most of all a hobby that I
                love.
              </p>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default About;
