import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./projects.scss";
import Card from "../../components/Card/Card";
import SEO from "../../components/SEO/SEO";

const Projects = () => {
  const projects = [
    {
      name: "Capstone Project - Frontend",
      frameworks: "React, Node.js, Sass",
      status: "In Progress",
      url: "https://github.com/KingChoices/tonshop",
    },
    {
      name: "Capstone Project - Backend",
      frameworks: "React, Node.js, Express, MySQL, Knex.js, JWT, Bcrypt",
      status: "In Progress",
      url: "https://github.com/KingChoices/tonshop-backend",
    },
  ];

  return (
    <>
      <SEO
        name={"Projects"}
        title={"Projects"}
        description={"Projects Page"}
        type={"website"}
        keywords={
          "projects, projects page, denzell merisier, software engineer, fullstack, web developer, web applications, websites"
        }
      />
      <div className="page__container">
        <header>
          <Navbar />
        </header>
        <main>
          <section className="projects__section">
            <h1 className="projects__section--title">Projects</h1>
            <div className="flex__container">
              {projects.map((project, index) => {
                return (
                  <Card
                    name={project.name}
                    frameworks={project.frameworks}
                    status={project.status}
                    url={project.url}
                    key={index}
                  />
                );
              })}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Projects;
