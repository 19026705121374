import React from "react";
import { Link } from "react-router-dom";
import "./navbar.scss";

const Navbar = () => {
  return (
    <>
      <nav className="nav__container">
        <div>
          <Link to="/" className="nav__links-home">
            <h2>Denzell Merisier</h2>
          </Link>
        </div>
        <div>
          <Link to="/about" className="nav__links">
            About
          </Link>
          <Link to="/projects" className="nav__links">
            Projects
          </Link>
          <Link to="/contact" className="nav__links">
            Contact
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
