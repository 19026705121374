import React from "react";
import { Link } from "react-router-dom";
import "./card.scss";

const Card = ({ name, frameworks, status, url }) => {
  return (
    <Link to={url} target="_blank" className="card__container--url">
      <div className="card__container">
        <h2 className="card__container--name">{name}</h2>
        <p className="card__container--frame">{frameworks}</p>
        <p className="card__container--status">{status}</p>
      </div>
    </Link>
  );
};

export default Card;
